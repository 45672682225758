import axios from "axios";

export const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  defaults: {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
  data: {},
});
