import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/app.css";

Vue.config.productionTip = false;

if (!["kz", "ru", "en"].includes(localStorage.getItem("lang"))) {
  localStorage.setItem("lang", "ru");
}

Vue.mixin({
  methods: {
    __(key) {
      return (
        key
          .split(".")
          .reduce(
            (a, b) => a[b],
            require(`@/lang/${localStorage.getItem("lang")}`).data
          ) ?? "Перевод отсутствует"
      );
    },
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
