<template>
  <div id="app">
    <Header ref="header" />
    <div class="iframe-wrapper">
      <Loader v-if="isFrameLoaded" />
      <div id="frame">
        <iframe id="iframe" ref="iframe" />
      </div>
    </div>
    <Footer ref="footer" />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";
import { request } from "@/api";

export default {
  components: {
    Footer,
    Header,
    Loader,
  },
  data() {
    return {
      isFrameLoaded: true,
      frame: {},
      isOpenSnackBar: false,
      snackbar: {},
      mainHeight: 736,
    };
  },
  mounted() {
    this.isFrameLoaded = true;
    request
      .post('token/get', {
        testmode: 0,
        lifetime: 3600,
        gtm: 'GTM-WQV2D69',
        language: localStorage.getItem('lang')
      })
      .then((res) => {
        this.frame = { ...this.frame, ...res.data };
      })
      .finally(() => {
        this.buildIframe();
      });

    window.addEventListener("message", (event) => {
      if (
        event.data.action === "update_token" &&
        event.data.status === "success"
      ) {
        this.snackbar = { text: event.data.data.expiredDate };
        this.openSnackBar(true);
        setTimeout(() => {
          this.openSnackBar(false);
        }, 6000);
      }
      if (
        event.data.action === "update_frame_height" &&
        event.data.status === "success"
      ) {
        const height = JSON.parse(event.data.data);
        const footerHeight = this.$refs.footer.$el.clientHeight;
        const headerHeight = this.$refs.header.$el.clientHeight;
        const minHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px)`;
        this.$refs.iframe.style.minHeight = minHeight;
        this.$refs.iframe.style.height = height.docHeight + "px";
        window.scrollTo({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });

      }
      if (event.data.action === "update_token" && event.data.status === "success") {
        window.location.reload();
      }
      if (event.data.action === "scroll_top" && event.data.status === "success") {
        window.scrollTo({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }

      if (event.data.action === "3ds" && event.data.status === "success") {
        const footerHeight = this.$refs.footer.$el.clientHeight;
        const headerHeight = this.$refs.header.$el.clientHeight;
        const minHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px)`;
        this.$refs.iframe.style.minHeight = minHeight;
        this.$refs.iframe.style.height = "700px";
      }
    });
  },
  methods: {
    buildIframe() {
      // const container = document.getElementById("frame");
      const iframe = document.getElementById("iframe");
      if (this.frame && this.frame.token) {
        iframe.allow = "camera *;microphone *";
        iframe.src = this.frame.url;
        iframe.classList.add("iframe");
        iframe.name = "iframe";
        iframe.frameborder = "0";
        // container.appendChild(iframe);
      }
      const handleLoad = () => {

          this.isFrameLoaded = false;
      }

      iframe.addEventListener('load', () => {
        handleLoad()
      })
    },
    openSnackBar(isOpen) {
      this.isOpenSnackBar = isOpen;
    },
  },
};
</script>
<style lang="stylus" scoped>
#app {
  background-color: #F4F6F9;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
</style>
