<template>
  <div class="loader">Loading...</div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.loader
    text-align: center
    height: 100%
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display flex
    justify-content: center
    align-items: center
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
    color: #ffffff;
</style>
