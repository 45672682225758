<template>
  <div>
    <div :class="['main', { 'main-header': isPopup }]">
      <div
        :class="([isMobile ? 'nav-items' : 'd-none d-lg-block'], 'main__inner')"
      >
        <nav class="nav-menu d-flex justify-content-between">
          <!-- <div class="align-self-center"> -->
          <div class="nav-items">
            <router-link :to="{ name: 'main' }"><Logo /></router-link>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "./icons/Logo.vue";

export default {
  components: {
    Logo,
  },
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      isMobile: false,
      menu: [
        {
          key: "about",
          url: "https://asiapay.kz/about",
        },
        {
          key: "developers",
          url: "https://docs.asiapay.kz",
        },
        {
          key: "contacts",
          url: "https://asiapay.kz/contacts",
        },
        {
          key: "search",
          url: "https://paycheck.asiapay.kz",
        },
      ],
    };
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  methods: {
    openPersonalAccount() {
      window.open(process.env.VUE_APP_ARM_HOST);
    },
    openMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        document.body.classList.add("menu-only");
      } else {
        document.body.classList.remove("menu-only");
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.nav-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-menu {
  width: 100%;
}

.main {
  padding: 24px;
  background: #F6F7F7;
}

.main__inner {
  max-width: 1212px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .main-header {
    display: none;
  }
}
</style>
